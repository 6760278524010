<template>
    <div style="padding-top: 10%">
        <v-col xs12>
            <v-row align-center justify-center row fill-height>
                <div>
                    <div class="text-xs-center">
                        <v-card flat>
                            <v-progress-circular  :size="100" :width="7" color="primary"  indeterminate></v-progress-circular>
                        </v-card>                   
                    </div> 
                </div>
            </v-row>
        </v-col>
    </div>
</template>

<script>
import SeguridadAPI from '@/api/seguridad'
import {mapActions} from 'vuex'

export default {
  data () {
    return {
      loading: true,
      value: false,
      mensaje: 'Autenticando...',
      progressColor: 'amber',
      alertType: 'warning'
    }
  },
  methods: {
    ...mapActions(['setError', 'setErrorMessage', 'setMessage', 'setUser', 'setPAWUrl']),
    redirectPAW () {
      let url = null
      if (this.url) {
        url = this.url
        window.location = url
      } else {
        SeguridadAPI.getUrlUserportal()
        .then(response => {
          url = response.data
          window.location = url
        })
        .catch(error => {
          this.setError(error)
        })
      }
    }
  },
  mounted () {
    // En el ambiente de desarrollo, autentica automáticamente
    if (process.env.NODE_ENV !== 'production') {
      SeguridadAPI.getCurrentIISUser()
        .then(response => {
          var info = response.data
          let user = {
            asociadoid: info.AsociadoId,
            codasociado: info.CodigoAsociado,
            nombre: info.Nombre,
            canalasociadoid: info.CanalAsociadoId,
            canalid: info.CanalId,
            rol: info.Rol,
            username: info.username
          }
          this.setUser(user)
          this.setPAWUrl('http://pawtest/userportal')
          if (user.rol === 'Comisiones') {
            this.$router.push('/asociados')
          } else {
            this.$router.push('/')
          }
        })
        .catch(error => {
          this.setError(error)
          this.$router.push('/login')
        })
    } else {
      SeguridadAPI.getCurrentIISUser()
        .then(response => {
          var info = response.data
          let user = {
            asociadoid: info.AsociadoId,
            codasociado: info.CodigoAsociado,
            nombre: info.Nombre,
            canalasociadoid: info.CanalAsociadoId,
            canalid: info.CanalId,
            rol: info.Rol,
            username: info.username
          }
          // let user = { username: response.data, codasociado: null }

          // user.rol = 'Comisiones'
          this.setUser(user)
          this.setPAWUrl('http://pawtest/userportal')
          if (user.rol === 'Comisiones') {
            this.$router.push('/asociados')
          } else {
            this.$router.push('/')
          }
        })
        .catch(error => {
          this.setError(error)
          this.$router.push('/login')
        })
    }
  }
}
</script>